import './index.scss';
import React from 'react';
import Button from '../../../../components/Button';
import Tick from '../../../../components/Icons/Tick';

export default function Educational() {
    return <section className='educational'>
        <header>Phi for Education</header>
        <p>
            Students and Educators
        </p>
        <Button to='/register' title='Apply to Phi for Education'>Apply now</Button>
        <section className='ticks'>
            <br />

            <article>
                <Tick/>
                <span className='text'>100% free when part of the curriculum</span>
            </article>

            <article>
                <Tick/>
                <span className='text'>85% discount for other educational use</span>
            </article>

            <article>
                <Tick/>
                <span className='text'>License can be renewed when it expires
                </span>
            </article>

            <article>
                <Tick/>
                <span className='text'>Full suite of Professional surface modelling tools and workflows</span>
            </article>

            <article>
                <Tick/>
                <span className='text'>Unlimited number of models and iterations</span>
            </article>
        </section>
    </section>;
}
