import React from 'react'
import { Helmet } from 'react-helmet';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
        }
    }
}

export function PricingTable(props: { table_id: string; public_key: string }) {
    return <>
        <Helmet>
            <script async src='https://js.stripe.com/v3/pricing-table.js'/>
        </Helmet>
        <stripe-pricing-table
            pricing-table-id={props.table_id}
            publishable-key={props.public_key}
        />
    </>
}
