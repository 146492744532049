import './index.scss';
import React from 'react';
import Button from '../../../../components/Button';
import Tick from '../../../../components/Icons/Tick';
import onshapeLogo from "../../../../images/onshape-phi-cloud-app.png"

export default function OnshapeSubsidy() {
    return <section className='onshapeSubsidy'>
        <header>50% off for Onshape users</header>
        <p>
            Are you on Onshape? <br />
            Get Phi Professional with a 50% discount! <br />
            <br />
            <a href="https://appstore.onshape.com/apps/Design%20&%20Documentation/BI4NF3BZAEQXBXOERCYO6NSHJ5Z62TB6ZI472VA=/description" target="_blank">
                <img className='onshape-cta' src={onshapeLogo} />
            </a>
        </p>
    </section>;
}
